var showMenu = false;

$(function() {
	

	$(window).on('load', function() {
		if ($('div').is('.info_text')) {
			infoTextHeightMin();
		}
	});
	$(window).on('resize', function() {
		if ($('div').is('.info_text')) {
			infoTextHeightMin();
		}
	});
	$('.open_text, .open_text_mob').on('click', function() {
		infoTextHeight();
	});
	$('.close_text').on('click', function() {
		infoTextHeightMin();
	});

	// $('.header_menu_btn').on('click', function() {
	// 	$('.header_menu_list').slideToggle();
	// 	// $('.menu_fade').toggle();
	// });
	// $('.menu_fade').on('click', function() {
		// $('.header_menu_list').slideUp();
		// $('.menu_fade').hide();
	// });


	$('.header_menu_btn').on('click', function () {
		if (showMenu == false) {
			showMenu = true;
			$('.header_menu_list').slideDown('fast');
			$('.header_menu_list_li.active').children('.header_menu_list_sub').addClass('active_list');
		}
		else {
			slideUpMenu();
			$('.header_menu_list_li.active').children('.header_menu_list_sub').removeClass('active_list');
		}
	});
	$('.header_menu_list_li').hover(function() {
		$('.header_menu_list_li').removeClass('active');
		$('.header_menu_list_sub').removeClass('active_list');
		$(this).addClass('active').children('.header_menu_list_sub').addClass('active_list');
	// $('.header_menu_list_li.active')
},
function() {
	$('.header_menu_list_sub').removeClass('active_list');
});

	$('body').on('click', function (event) {
		var object = $('.header_menu').attr('class');
		var targetObject = event.originalEvent.path;
		var existMenu = false;
		var existInfo = false;
    // console.log(targetObject);

    targetObject.forEach(function (obj, i, arr) {
    	if (i == (arr.length) - 7) {
    		var exist_class = $(obj).is('.header_menu');
    		if (exist_class == true) {
    			existMenu = true;
    		}
    	}
    });
    if (existMenu == false) {
    	slideUpMenu();
    }

    targetObject.forEach(function (obj, i, arr) {
    	if (i == (arr.length) - 12) {
    		var exist_class = $(obj).is('.info_icon');
    		if (exist_class == true) {
    			existInfo = true;
    		}
    	}
    	console.log(arr);
    	console.log(i, obj);

    	if (i == (arr.length) - 12) {
    		var exist_class = $(obj).is('.info_block');
    		if (exist_class == true) {
    			existInfo = true;
    		}
    	}
    });
    if (existInfo == false) {
    	// slideUpMenu();
    	$('.info_block').slideUp();
    	$('.info_icon').removeClass('active');

    }




  });
	$('.close_menu').on('click', function() {
		slideUpMenu();
		$('.header_menu_list_li.active').children('.header_menu_list_sub').removeClass('active_list');
	});

// if ($('div').is('.slidedown')) {
	$('.slidedown').on('click', function () {
		console.log(1);
		categoryInfoBlockHeight();
		$(this).removeClass('show');
		$(this).addClass('hide');
		$('.slideup').removeClass('hide');
		$('.slideup').addClass('show');
		$('.category_info_block').addClass('show');

	})
// }

// if ($('div').is('.slideup')) {
	$('.slideup').on('click', function () {
		console.log(2);
		// categoryInfoBlockHeight();
		$('.category_info_block').height(150);
		$(this).removeClass('show');
		$(this).addClass('hide');
		$('.slidedown').removeClass('hide');
		$('.slidedown').addClass('show');
		$('.category_info_block').removeClass('show');

	})
// }

$('.dropdown_item').on('click', function () {
	$('.dropdown_list').slideToggle();
	$(this).toggleClass('active');
})
$('.dropdown_list li').on('click', function () {
	var text = $(this).html();
	$('.dropdown_list li').removeClass('choise');
	// $(this).addClass('choise');
	$('.dropdown_list').slideUp();
	$('.dropdown_item .dropdown_item_name').html('').html(text);
	$('.dropdown_item').removeClass('active');

})


$('.info_icon').on('click', function() {
	// console.log('asd');
	// $('.info_block').removeClass('active').slideUp();
	if($(this).hasClass('active')){
		$(this).removeClass('active')
		.next('.info_block')
		.slideUp();
	}
	else if(!$(this).hasClass('active')){
		$('.info_block').slideUp();
		$('.info_icon').removeClass('active')
		$(this).addClass('active')
		.next('.info_block')
		.slideDown();
	}


	// 	$(this).next('.info_block').slideToggle();
	
	// $(this).toggleClass('active');
	// if($('.info_icon').hasClass('active')){
	// }
});

$('.ingredient_btn').on('click', function() {
	$(this).parent().parent().parent().next().children('.ingedients_block').slideToggle();
	$(this).toggleClass('active');
});






});